import "./App.css";
import { useState, useMemo, useEffect } from "react";
import { useContractRead, useContractWrite } from "wagmi";
import { useConnect, useAccount, useNetwork } from "wagmi";
import uzdAbi from "./abi/uzd.json";
import zethAbi from "./abi/uzd.json";
import { BIG_ZERO, getFullDisplayBalance } from "./formatbalance";
import BigNumber from "bignumber.js";

const UZD_CLAIMING_ADDRESS = "0xa655Aa809D1FE7a55e22425780bb676E8AE0A143";
const ZETH_CLAIMING_ADDRESS = "0x6756EfFa8aABeB9031279e4C71d8c1BD3Ae8f9Ef";

function App() {
    const { connect, connectors } = useConnect();
    const { address, isConnected } = useAccount();
    console.log(`Address: ${address}. Connected: ${isConnected}`);
    // const { isConnected } = useAccount();
    // const address = "0x7b7d80c40415f744864f051b806b466e2fbb8e68";
    const { chain } = useNetwork();
    const isEth = isConnected && chain && chain.id === 1;
    const [mode, setMode] = useState("UZD");
    const [wmShow, setWmShow] = useState(false);
    const poolAddress = useMemo(() => {
        return mode === "UZD" ? UZD_CLAIMING_ADDRESS : ZETH_CLAIMING_ADDRESS;
    }, [mode]);
    const claimsAddress = useMemo(() => {
        // return "0x7b7d80c40415f744864f051b806b466e2fbb8e68";
        return address || "0x0000000000000000000000000000000000000000";
    }, [address]);

    function onConnect(connectorId: string) {
        switch (connectorId) {
            case "injected":
                connect({ connector: connectors[3] });
                break;
            case "walletconnect":
                connect({ connector: connectors[2] });
                break;
            case "coinbase":
                connect({ connector: connectors[1] });
                break;
            case "zerion":
                connect({ connector: connectors[2] });
                break;
        }

        setWmShow(false);
    }

    const { data, isError, isLoading }: any = useContractRead({
        address: poolAddress,
        abi: mode === "UZD" ? uzdAbi : zethAbi,
        functionName: "claims",
        args: [claimsAddress],
        onError(error) {
            console.log("Error while retrieving claim data:");
            console.log(error);
        },
        onSuccess(resp: Array<any>) {
            // claimer
            // balance
            // batch
            // withdrew
            console.log(
                `Claims (contract ${poolAddress}) for address ${claimsAddress}. Balance: ${resp[1]}, batch: ${resp[2]}, withdrew: ${resp[3]}, pool: ${mode} (${poolAddress})`
            );
        },
    });

    const claimedBatchId = useMemo(() => {
        return data ? data[2] : 0;
    }, [data]);

    const alreadyWithdrew = useMemo(() => {
        return data ? data[3] : false;
    }, [data]);

    const [userBatchStatus, setUserBatchStatus] = useState(false);

    const { data: finishedStatus }: any = useContractRead({
        address: poolAddress,
        abi: mode === "UZD" ? uzdAbi : zethAbi,
        functionName: "batchesFinished",
        args: [claimedBatchId || 0],
        onSuccess(status: boolean) {
            console.log(
                `Batch ${claimedBatchId} status: ${status}. ${
                    status ? "Ready" : "NOT ready"
                } for withdraw.`
            );
            setUserBatchStatus(status);
        },
        onError(error) {
            console.log("Error while retrieving batch status");
            console.log(error);
        },
    });

    const [alreadyClaimed, setAlreadyClaimed] = useState(false);

    const balance = useMemo(() => {
        if (data && !isLoading) {
            return new BigNumber(data[1]);
        }

        return BIG_ZERO;
    }, [isLoading, data]);

    // Withdrawal request
    const {
        data: requestData,
        isLoading: requestLoading,
        isSuccess,
        write: requestClaim,
    } = useContractWrite({
        address: poolAddress,
        abi: mode === "UZD" ? uzdAbi : zethAbi,
        functionName: "requestClaim",
        onSuccess(data) {
            alert(
                "Claim was successfully sent. Please, check once in a few days the status."
            );
        },
    });

    // Claim funds
    const {
        data: claimResponse,
        isLoading: claimInProgress,
        isSuccess: claimSuccess,
        write: claim,
    } = useContractWrite({
        address: poolAddress,
        abi: mode === "UZD" ? uzdAbi : zethAbi,
        functionName: "withdrawClaim",
        onSuccess(data) {
            alert("Claim finished. Check the balances in a few minutes");
        },
        onError(error) {
            alert(`Can't claim due to error: ${error}`);
        },
    });

    useEffect(() => {
        const claimedBefore = data && Number(data[2]) !== 0;
        const claimedNow = isSuccess;

        if (claimedBefore || claimedNow) {
            setAlreadyClaimed(true);
        } else {
            setAlreadyClaimed(false);
        }
    }, [data, isSuccess, address, chain, mode]);

    const [canClaimFunds, setCanClaimFunds] = useState(false);

    useMemo(() => {
        if (!alreadyClaimed) {
            setCanClaimFunds(false);
        }

        if (userBatchStatus) {
            setCanClaimFunds(true);
        }
    }, [alreadyClaimed, userBatchStatus]);

    const submitEnabled = useMemo(() => {
        if (isConnected && address && balance.toNumber() && !requestLoading) {
            return true;
        }

        return false;
    }, [isConnected, address, balance, requestLoading]);

    const fBalance = useMemo(() => {
        if (!data) {
            return "$0";
        }

        return Number(
            getFullDisplayBalance(new BigNumber(data[1]), 18, 7)
        ).toLocaleString("en", {
            maximumFractionDigits: mode === "UZD" ? 3 : 6,
        });
    }, [data, mode]);

    return (
        <div>
            <div className="App d-flex justify-content-center align-items-center">
                <div className="modal d-block">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <p className="top-info">
                                    Users who were affected by the hack on the
                                    night of August 13-14 can now access UZD &
                                    zETH collateral for the block before the
                                    hack.
                                </p>
                                <div className="steps">
                                    <p>
                                        To retrieve the funds, you need to
                                        follow three steps:
                                    </p>
                                    <div
                                        className={`step-1 text-center ${
                                            isConnected ? "d-none" : ""
                                        }`}
                                    >
                                        <p>1. Connect your wallet</p>
                                        <input
                                            type="button"
                                            className="btn btn-light zun-btn"
                                            value="Connect your wallet"
                                            onClick={() => {
                                                setWmShow(true);
                                            }}
                                        />
                                    </div>
                                    <div
                                        className={`step-1 claim-props text-center ${
                                            !isConnected ? "d-none" : ""
                                        }`}
                                    >
                                        <div className="panel coin-selector">
                                            <div className="coin-selector">
                                                <div>
                                                    <svg
                                                        width="39"
                                                        height="24"
                                                        viewBox="0 0 39 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="pool-logo"
                                                    >
                                                        <path
                                                            d="M38.9991 11.7551L38.9998 11.264C39.002 7.51603 39.0043 4.55559 37.5976 2.65241C37.0972 1.98244 36.446 1.43219 35.6928 1.04419C34.5988 0.447772 33.5285 0.299927 32.5838 0.169407L32.5077 0.159302C31.6444 0.0467917 30.7744 -0.00657376 29.9036 0.000645487H9.09622C8.22547 -0.00657376 7.35547 0.0467932 6.49212 0.1593L6.41599 0.169406C5.47133 0.299925 4.40102 0.447771 3.30705 1.04418C2.55383 1.43219 1.90241 1.98244 1.40236 2.65241C-0.00449991 4.55559 -0.00243395 7.51603 0.00022813 11.264L0.000449344 11.7551L0.00022813 12.2462C-0.00243395 15.9942 -0.00450015 18.9546 1.40243 20.8578C1.90241 21.5278 2.55383 22.078 3.30704 22.466C4.40102 23.0624 5.47133 23.2103 6.41599 23.3408L6.49212 23.3509C7.35547 23.4634 8.22547 23.5168 9.09621 23.5096H29.9036C30.7744 23.5168 31.6444 23.4634 32.5077 23.3509L32.5838 23.3408C33.5285 23.2103 34.5988 23.0624 35.6928 22.466C36.446 22.078 37.0972 21.5278 37.5976 20.8578C39.0043 18.9546 39.002 15.9942 38.9998 12.2462L38.9991 11.7551Z"
                                                            fill="url(#paint0_linear_1_2)"
                                                        />
                                                        <path
                                                            d="M12.0956 8.24817V12.4005C12.0956 13.9292 10.6891 15.1686 8.9552 15.1686C7.7986 15.1686 6.86125 14.1357 6.86125 12.8617V8.24817H5V12.8499C4.99836 13.8882 5.41145 14.8849 6.14894 15.622C6.88644 16.3592 7.88833 16.7768 8.9355 16.7835C9.70875 16.7885 10.4744 16.6321 11.1827 16.3244C11.58 16.1484 11.9017 15.8387 12.0909 15.4501V16.5493H12.0956V16.5529H13.9569V8.24817H12.0956Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M30.6037 5.48132V5.48165H30.6011V9.35604C30.3774 8.97187 30.0345 8.66961 29.6235 8.49425C28.9449 8.1875 28.209 8.02542 27.4633 8.01848C24.7014 8.01848 22.4622 9.98031 22.4622 12.4008C22.4622 14.8208 24.2325 16.783 26.4168 16.783C26.9361 16.7831 27.4504 16.6816 27.9302 16.4846C28.41 16.2875 28.846 15.9987 29.2132 15.6346C29.5804 15.2704 29.8717 14.8382 30.0704 14.3624C30.2691 13.8867 30.3714 13.3768 30.3714 12.8618H28.5102C28.5102 14.1357 27.573 15.1685 26.4168 15.1685C25.2603 15.1685 24.3231 13.9292 24.3231 12.4008C24.3231 10.8724 25.7295 9.63307 27.4633 9.63307C29.1977 9.63307 30.6039 10.8724 30.6039 12.4008V16.5525H32.4648V5.48132H30.6037Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M14.7551 9.14264V9.87758H19.8054L14.7551 14.8562V16.5206H22.4263V14.8562H21.9649V14.8561H17.5424L17.8184 14.5413C17.8365 14.5224 17.8544 14.5034 17.8718 14.4837L18.3708 13.9182L22.4263 9.80072V8.25073H14.7551V9.14264Z"
                                                            fill="white"
                                                        />
                                                        <defs>
                                                            <linearGradient
                                                                id="paint0_linear_1_2"
                                                                x1="5.5102"
                                                                y1="20.9388"
                                                                x2="21.6735"
                                                                y2="3.75775e-06"
                                                                gradientUnits="userSpaceOnUse"
                                                            >
                                                                <stop stopColor="#FE9604" />
                                                                <stop
                                                                    offset="1"
                                                                    stopColor="#FE9F04"
                                                                />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                </div>
                                                <div>
                                                    <input
                                                        id="blue-toggle"
                                                        type="checkbox"
                                                        className="itoggle orange"
                                                        checked={mode !== "UZD"}
                                                        onChange={(e) => {
                                                            setMode(
                                                                mode === "UZD"
                                                                    ? "ZETH"
                                                                    : "UZD"
                                                            );
                                                        }}
                                                    />
                                                    <label htmlFor="blue-toggle"></label>
                                                </div>
                                                <div>
                                                    <svg
                                                        width="42"
                                                        height="25"
                                                        viewBox="0 0 42 25"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="pool-logo"
                                                    >
                                                        <path
                                                            d="M41.9993 12.5003L42 12.0092C42.0023 8.26119 42.0045 5.30073 40.5978 3.39755C40.0974 2.72757 39.4462 2.17731 38.693 1.78931C37.599 1.19289 36.5287 1.04505 35.584 0.914526L35.5079 0.90442C34.6445 0.791909 33.7745 0.738543 32.9038 0.745763H9.09627C8.22552 0.738543 7.35551 0.791911 6.49216 0.904418L6.41603 0.914524C5.47136 1.04504 4.40104 1.19289 3.30707 1.78931C2.55385 2.17731 1.90242 2.72756 1.40237 3.39754C-0.00449991 5.30073 -0.00243396 8.26119 0.000228132 12.0092L0.000449346 12.5003L0.000228132 12.9914C-0.00243396 16.7394 -0.00450015 19.6998 1.40244 21.603C1.90242 22.273 2.55385 22.8233 3.30706 23.2113C4.40104 23.8077 5.47136 23.9555 6.41603 24.086L6.49215 24.0962C7.35551 24.2087 8.22552 24.262 9.09627 24.2548H32.9038C33.7745 24.262 34.6445 24.2087 35.5079 24.0962L35.584 24.086C36.5287 23.9555 37.599 23.8077 38.693 23.2113C39.4462 22.8233 40.0974 22.273 40.5978 21.603C42.0045 19.6998 42.0023 16.7394 42 12.9914L41.9993 12.5003Z"
                                                            fill="url(#paint0_linear_1_3)"
                                                        />
                                                        <path
                                                            d="M5 8.02933V8.62976H10.5956L6.50465 12.6972V14.0569H14.2232V12.6972H13.8495V12.6971H8.76246L8.98604 12.4399C9.00071 12.4245 9.01521 12.4089 9.02925 12.3929L9.43348 11.9309L12.7185 8.56695V7.30066H5V8.02933Z"
                                                            fill="url(#paint1_linear_1_3)"
                                                        />
                                                        <path
                                                            d="M24.3037 15.4414V14.1521L24.3269 10.7043H26.1322V9.31986H24.3361L24.3543 6.6093H24.3037V6.60046H22.4902V9.31986H22.0372V9.32067H22.0369V10.6809H22.0372V10.7043H22.4902V15.4414C22.4902 16.6934 23.7083 17.7084 25.2106 17.7084H26.3441V16.1216H25.2106C24.7101 16.1216 24.3037 15.8169 24.3037 15.4414Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M32.0956 9.15359C31.2942 9.15092 30.5047 9.29383 29.8666 9.61298C29.6031 9.72987 29.1853 10.0791 28.98 10.4614V6.59387H27.106V17.7113H28.98V13.2327C29.1515 11.8473 30.4727 10.7692 32.0764 10.7692C33.2236 10.7692 34.1536 11.8025 34.1536 13.0772V17.6931H35.9999V13.0894C35.9999 10.9281 34.2569 9.1609 32.0956 9.15359Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M16.7984 9.09583C14.1061 9.09583 11.9236 11.0245 11.9236 13.4035C11.9236 15.7826 14.1061 17.7112 16.7984 17.7112C18.7572 17.7112 20.4458 16.69 21.2202 15.2173H19.0794C18.519 15.7741 17.7046 16.1242 16.7984 16.1242C15.3721 16.1242 14.1738 15.2572 13.8334 14.0838H21.612C21.652 13.8625 21.6724 13.6351 21.6724 13.4035C21.6724 13.1719 21.652 12.9447 21.612 12.7234C21.2433 10.6671 19.2282 9.09583 16.7984 9.09583ZM16.7984 10.6829C18.2156 10.6829 19.4079 11.5399 19.7562 12.703H13.9024C13.9024 12.5623 13.9767 12.3583 14.0612 12.1863C14.5641 11.295 15.6004 10.6829 16.7984 10.6829Z"
                                                            fill="white"
                                                        />
                                                        <defs>
                                                            <linearGradient
                                                                id="paint0_linear_1_3"
                                                                x1="5.51024"
                                                                y1="21.684"
                                                                x2="21.6736"
                                                                y2="0.745112"
                                                                gradientUnits="userSpaceOnUse"
                                                            >
                                                                <stop stopColor="#545454" />
                                                                <stop
                                                                    offset="1"
                                                                    stopColor="#878787"
                                                                />
                                                            </linearGradient>
                                                            <linearGradient
                                                                id="paint1_linear_1_3"
                                                                x1="5.4285"
                                                                y1="7.86314"
                                                                x2="13.5363"
                                                                y2="13.3205"
                                                                gradientUnits="userSpaceOnUse"
                                                            >
                                                                <stop stopColor="white" />
                                                                <stop
                                                                    offset="0.48479"
                                                                    stopColor="#FDFDFD"
                                                                    stopOpacity="0.99393"
                                                                />
                                                                <stop
                                                                    offset="0.65189"
                                                                    stopColor="#FCFCFC"
                                                                    stopOpacity="0.98669"
                                                                />
                                                                <stop
                                                                    offset="0.87254"
                                                                    stopColor="#595959"
                                                                    stopOpacity="0.41453"
                                                                />
                                                                <stop
                                                                    offset="1"
                                                                    stopOpacity="0.1"
                                                                />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel">
                                            <div className="title">
                                                Refund Amount
                                            </div>
                                            <div className="value">
                                                {!isLoading && !isError && (
                                                    <div>{fBalance}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="step-2 text-center">
                                        <p>2. Submit a withdrawal request</p>
                                        <input
                                            type="button"
                                            className={`btn btn-light zun-btn ${
                                                submitEnabled ? "" : "disabled"
                                            } ${
                                                alreadyClaimed ? "claimed" : ""
                                            }`}
                                            value={`${
                                                !alreadyClaimed
                                                    ? "Submit Withdrawal Request"
                                                    : "Already requested"
                                            }`}
                                            onClick={() => {
                                                requestClaim();
                                                // ClaimingStrat.requestClaim();
                                            }}
                                        />
                                    </div>
                                    <div className="step-3 text-center">
                                        <p>3. Initiate the fund claim</p>
                                        <input
                                            type="button"
                                            className={`btn btn-light zun-btn ${
                                                !canClaimFunds ||
                                                !finishedStatus
                                                    ? "disabled"
                                                    : ""
                                            } ${
                                                alreadyWithdrew ? "claimed" : ""
                                            }`}
                                            value={`${
                                                alreadyWithdrew
                                                    ? "Already withdrawn"
                                                    : "Claim Funds"
                                            }`}
                                            onClick={() => {
                                                claim();
                                            }}
                                        />
                                        <div className="attention-claim">
                                            Attention! The approximate
                                            processing time for fund claim
                                            requests is around 2-3 days.
                                        </div>
                                    </div>
                                </div>
                                <div className="bottom-info text-center">
                                    If you have any questions or difficulties,
                                    please reach out to the Community{" "}
                                    <a
                                        href="https://discord.com/invite/BnC6kTWkUe"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        chat
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`modal fade ${wmShow ? "d-block show" : ""}`}
                id="wallets-modal"
                data-bs-backdrop="static"
                tabIndex={-1}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body d-flex gap-3 flex-row flex-wrap WalletsModal  justify-content-center align-items-center">
                            <button
                                onClick={() => onConnect("injected")}
                                className="d-inline-flex flex-column bg-transparent metamask"
                            >
                                <img src="/metamask.svg" alt="" />
                                <span className="name">Metamask Wallet</span>
                                <span className="connect">Connect</span>
                            </button>
                            <button
                                onClick={() => onConnect("walletconnect")}
                                className="d-inline-flex flex-column bg-transparent walletconnect"
                            >
                                <img src="/wallet-connect.svg" alt="" />
                                <span className="name">Wallet Connect</span>
                                <span className="connect">Connect</span>
                            </button>
                            <button
                                onClick={() => onConnect("coinbase")}
                                className={`d-inline-flex flex-column bg-transparent coinbase ${
                                    isEth ? "disabled" : ""
                                }`}
                            >
                                <img src="/wallet-link.svg" alt="" />
                                <span className="name">Coinbase Wallet</span>
                                {isEth && (
                                    <span className="badge bg-secondary">
                                        only ETH
                                    </span>
                                )}
                                {!isEth && (
                                    <span className="connect">Connect</span>
                                )}
                            </button>
                            <button
                                onClick={() => onConnect("zerion")}
                                className={`d-inline-flex flex-column bg-transparent zerion ${
                                    isEth ? "disabled" : ""
                                }`}
                            >
                                <img src="/zerion-wallet.svg" alt="" />
                                <span className="name">Zerion Wallet</span>
                                {isEth && (
                                    <span className="badge bg-secondary">
                                        only ETH
                                    </span>
                                )}
                                {!isEth && (
                                    <span className="connect">Connect</span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
